import React from 'react'
import "../../style.scss";
import arrow from '../../img/arrow.png'
import Title from './Title';
import Modal from '../Modal.jsx'
import { useRef,useState,useEffect } from 'react';
import Form from '../form/Form.jsx';
import mainLogo from '../../img/mainLogoPc.png'
import logoAnimate from '../../img/guar_logo_v02.mp4'
const Main = (props)=>{

  const [isScrolled, setIsScrolled] = useState(false);
  

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 200) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);


  
  const modalRef = useRef();
  const handleOpenModal = () => {
    window.BMDataLayer = window.BMDataLayer || [];
    window.BMDataLayer.push({
      conversionID: "66586e2f02d8f83749606fc8",
      eventId: "TapButtomJoinWL",
      event: "conversion",
      eventValue: "{ConversionValueUSD}",
    });
    modalRef.current.open();
  };
   const handleClick = (id) => {
    

     const targetElement = document.getElementById(id);

     if (targetElement) {
       targetElement.scrollIntoView({
         behavior: "smooth",
       });
     }
   };
   const closeModal = () => {
     if (modalRef.current) {
       modalRef.current.close();
     }
   };
  return (
    <section className="main">
      <div className="main__wraper">
        <div className="main__body">
          <Title />
          <p className="main__sup-title ">Innovative Liquidity Solutions</p>
          <p className="main__text">
            Our platform is based on the EVM-agnostic + Rell (Chromia) and
            specializes in providing stablecoins and crypto with guaranteed
            yields
          </p>
          <div className="main__buttons buttons-main">
            <button
              onClick={handleOpenModal}
              className="buttons-main__batton button"
            >
              join waitlist
            </button>
            <a
              onClick={(e) => handleClick("Our Features")}
              href="#Our Features"
              className="buttons-main__batton-transparent"
            >
              Features <img src={arrow} alt="arrow" />
            </a>
          </div>
        </div>

        <Modal id="modalClose" ref={modalRef}>
          <Form closeModal={closeModal} ref={modalRef} />
        </Modal>
          <video className="main__img" autoPlay loop muted playsInline>
            <source src={logoAnimate} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        {/* <div className="main__img">
          <img src={mainLogo} alt="logo" />
        </div> */}
      </div>

      <div
        className={
          isScrolled
            ? " main__block block-main  visible"
            : " main__block block-main not-visible  "
        }
      >
        <p className="block-main__sup-title">
          Our consistent revenue streams are generated through DEX commissions,
          which
        </p>
        <h3 className="block-main__title">
          guarantees our users a dependable and consistent return
        </h3>
        <p className="block-main__text text">
          We prioritize risk management by allocating capital across a variety
          of DeFi liquidity pools and utilizing a hedgers pool for added
          security
        </p>
      </div>
    </section>
  );
}

export default Main